body {
  background-color: #fff !important;
  font-family: "lato" !important;
}

.App {
  text-align: center;
}

.ant-layout {
  background-color: #fff !important;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #4a4a4a;
  position: fixed;
  z-index: 1 !important;
  width: 100%;
  min-height: 64px !important;
  max-height: 104px !important;
  /* max-width: 1600px; */
}

.App-link {
  color: #61dafb;
}

.Body {
  width: 100%;
}

.Content {
  margin-top: 64px !important;
}

.header {
  font-size: 18px;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: unset !important;
}

.section {
  padding-top: 15px;
  padding-bottom: 15px;
  /* max-width: 1600px; */
  margin: 0 auto;
}

.center-container {
  width: 85%;
  display: inline-block;
}

.navBarContainer {
  /* padding-left: 2rem;
  padding-right: 2rem; */
  /* width: 95%; */
}

.navBarContainer > .ant-menu-horizontal {
  border-bottom: none;
}

.navBarContainer > .ant-menu-horizontal > .ant-menu-item:hover,
.navBarContainer > .ant-menu-horizontal > .ant-menu-submenu:hover,
.navBarContainer > .ant-menu-horizontal > .ant-menu-item-active,
.navBarContainer > .ant-menu-horizontal > .ant-menu-submenu-active,
.navBarContainer > .ant-menu-horizontal > .ant-menu-item-open,
.navBarContainer > .ant-menu-horizontal > .ant-menu-submenu-open,
.navBarContainer > .ant-menu-horizontal > .ant-menu-item-selected,
.navBarContainer > .ant-menu-horizontal > .ant-menu-submenu-selected {
  /* color: #ffcb1f; */
  /* border-bottom-color: #ffcb1f; */
  border: none;
}

.ant-layout-header {
  padding: 0 !important;
  background-color: #fff !important;
}

#navBarLogo:hover,
#navBarLogo {
  border: none !important;
  color: #fff !important;
  transition: none !important;
  box-shadow: none !important;
}

.card {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.card > .ant-card {
  background: transparent !important;
}

.color-333132 {
  color: #333132;
}

.color-414042 {
  color: #414042;
}

.barsBtn:before {
  content: attr(x);
  width: "10px";
  height: "10px";
}

.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #ffcb1f !important;
  font-weight: bold;
}

#right_ {
  border-bottom: none;
}

#right_ > .ant-menu-item:hover,
#right_ > .ant-menu-submenu:hover,
#right_ > .ant-menu-item-active,
#right_ > .ant-menu-submenu-active,
#right_ > .ant-menu-item-open,
#right_ > .ant-menu-submenu-open,
#right_ > .ant-menu-item-selected,
#right_ > .ant-menu-submenu-selected {
  /* color: #ffcb1f; */
  /* border-bottom-color: #ffcb1f; */
  /* border-bottom: none; */
  border-bottom-color: #fff !important;
  /* transition: none; */
  /* transition-delay: none; */
  /* transition-duration: none; */
  /* transition-property: none; */
  /* transition-timing-function: none; */
}

.LocationCol {
  max-height: 70vh;
}

.LocationCol > div {
  height: 100%;
}

.carousel-slider {
  height: 100%;
}

/* .carousel .slide img,
.carousel img {
  width: auto !important;
} */

@media only screen and (min-width: 768px) {
  .LocationCol {
    margin-right: unset;
  }
  .navBarBtn {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  #FooterElement {
    display: none;
  }

  #MobileFooterElement {
    display: inline-block !important;
  }

  .navBarContainer {
    /* padding-left: 2rem;
    padding-right: 2rem; */
    width: 100%;
  }
  .navBarBtn {
    display: inline-block !important;
  }
  .Right_Menu {
    display: none;
  }
  .colCard {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  .barsMenu {
    margin-left: auto;
    display: flex;
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 8px;
    background: none;
  }

  .cardRow {
    display: flex;
    flex-direction: column;
  }

  .card {
    padding: 5px !important;
    float: none !important;
    display: inline-block !important;
  }

  .center-container {
    width: 90%;
    display: inline-block;
  }
}

.font24 {
  font-size: 16px;
}

@media only screen and (max-width: 576px) {
  .PricingTable {
    white-space: pre;
  }
  .LocationsDesc {
    max-height: unset !important;
  }
}

@media only screen and (max-width: 768px) {
  .PricingTable {
    white-space: pre;
  }
  .LocationsDesc {
    max-height: unset !important;
  }
}

@media only screen and (min-width: 1600px) {

  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu{
    top: 4px !important;
  }
  
  .header {
    font-size: 24px;
  }

  .App-header {
    height: 104px !important;
  }
  .Content {
    margin-top: 104px !important;
  }

  .ant-menu-item {
    font-size: 24px !important;
  }

  .font28 {
    font-size: 28px;
  }

  .font24 {
    font-size: 24px !important;
    line-height: 46px;
    letter-spacing: 0.92;
  }

  .font24 > div > div {
    font-size: 24px !important;
  }

  .font20 {
    font-size: 20px;
  }

  body {
    font-size: 20px !important;
  }

  .ant-table-column-title {
    font-size: 24px;
    height: 48px;
  }

  #nxtBtn {
    font-size: 24px;
    height: 48px;
    display: none;
  }

  .ant-table {
    font-size: unset !important;
  }
}

.ant-calendar-picker {
  width: 100% !important;
}

.ant-cascader-menu {
  height: auto !important;
}

.ant-row {
  margin: 0 !important;
}

.LocationsDesc {
  max-height: 70vh;
  overflow: hidden;
}

#nxtBtn {
  border-radius: 0;
  font-weight: bold;
  background-color: #ffcb1f;
  color: #fff;
  width: 100%;
  display: none;
}

.ant-input {
  border-radius: 0 !important;
}

.ant-table-thead > tr {
  background: none !important;
}

.ant-table-tbody > tr > td {
  border: none !important;
}

.ant-table-thead > tr > th {
  border-bottom: 1px solid #4a4a4a !important;
}

.locationCard {
  text-align: left !important;
  height: 100%;
}

.rightAlign {
  text-align: right !important;
}

.ant-table-thead > tr > th {
  font-weight: bold !important;
}

.ant-btn {
  border: none !important;
}

.active {
  font-weight: bold;
}

.active > span {
  border-bottom: 2px solid #ffcb1f;
}

.navunderline {
  font-size: 16px;
}

#map {
  height: 40vh;
}

#contactLocationsrow {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

#MobileFooterElement {
  display: none;
}
